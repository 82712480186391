import GoodsColumns from '@/components/zh/goods-columns.vue'
import MSku from '@/components/zh/m-sku.vue'
import MAnimate from '@/components/zh/cart-animate.vue'
import CartFlex from '@/components/zh/cart-flex.vue'
import MDivider from '@/components/zh/m-divider.vue'

import {
    getHistory,
    getHotSearch,
    // getWebSearch,
    delHistory,
    searchGoods,
    search_mini,
    searchGift,
    searchMerchant,
    searchPresale,
    cut_search
} from '@/api/zh/search.js'
import {hotGoods} from '@/api/zh/index.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
    components: {
        GoodsColumns,
        MSku,
        MAnimate,
        CartFlex,
        MDivider
    },
    mixins: [MixinScroll],
    data() {
        return {
            searckKey: '',
            isSearch: false,
            start: false, // 是否开启加入购物车动画
            start_dom: null, // 开启动画的起点DOM 
            skuModal: false, // 控制显示 选择sku加入购物车面板
            historyList: [],
            hotList: [],
            webList: [],
            page: 1,
            islock: false,
            sortType: 1,
            goodsList: [],
            GuessList: [],
            gPage: 1,
            noMore: false,
            searchType: '',
            goodsId: '',

            merchantId: '',
            isDel: false,
            aloading: true,
            moreList: []
        };
    },
    methods: { // 内容滑动事件
        handleScroll(e) {
            if (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <= 50 && !this.isSearch) {
                if (!this.islock) {
                    e.preventDefault()
                    e.stopPropagation()
                    this.islock = true
                    this.page++
                    this.getWebSearchHandle()
                }
            }
        },
        goodsHandleScroll(e) {
            if (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <= 50 && this.isSearch) {
                if (!this.islock) {
                    e.preventDefault()
                    e.stopPropagation()
                    this.islock = true
                    this.gPage++
                    this.judgeSearchMethods()
                }
            }
        },
        onSearch() {
            // 点击键盘搜索
            this.isSearch = true
            this.noMore = false
            this.page = 1
            this.gPage = 1
            this.goodsList = []
            // console.log(window.location.href.split('s=presale&key')[1])
            if (window.location.href.split('s=presale&key=')[1] != '' && window.location.href.split('s=presale&key=')[1] != undefined) {
                this.$router.replace({
                    path: "/zh/search",
                    query: { s: "presale", key: this.searckKey},
                }) 
            }else{
               if (this.searckKey) {
                this.$router.replace({
                    path: "/zh/search",
                    query: { searchKey: encodeURIComponent(this.searckKey) },
                 })
               }  
            }
            
            
            this.judgeSearchMethods()
            this.getGuessLike()
        },
        onCancel() {
            // 点击取消
            let back = localStorage.getItem('back_path')
            if (!back) {
                back = '/'
            }
            localStorage.setItem('back_path', '/')
            this.$router.push({
                path: localStorage.getItem('back_path')
            })
        },
        onDelete() {
            // 删除历史搜索
            this.$dialog.confirm({
                title: '提醒',
                message: '确认清空搜索历史吗？',
            }).then(() => {
                // on confirm
                this.$notify({
                    type: 'success',
                    message: '删除成功'
                });
                delHistory('').then(res => {
                    if (res) {
                        this.historyList = []
                    }
                })
            }).catch(() => {
                // on cancel
                this.$notify({
                    message: '取消删除',
                    color: '#666',
                    background: '#e1e1e1',
                })
            })
        },
        gotoCart() {
            // 前往购物车
            this.$router.push({
                path: '/zh/cart'
            })
        },
        backStep() {
            if (this.$route.query.b && this.$route.query.b == 1) {
                this.$router.back()
                return false
            }
            // 返回上一步
            if (!this.searchType || this.searchType == '') {
                this.isSearch = false
                this.$router.replace('/zh/search')
                this.getHistoryHandle()
            } else {
                this.$router.back()
            }
            this.$refs.scroll.scrollTop = 0

        },
        handleCart(goods, e) {
            // 商品列表购物车点击事件
            this.goodsId = goods
            this.skuModal = true
            this.start_dom = e
        },
        handleCartSuccess() {
            // sku 弹窗加入购物车成功回调
            this.skuModal = false
            let timer = setTimeout(() => {
                this.start = true
                clearTimeout(timer)
            }, 300)

        },
        handleCartEnd() {
            // 加入购物车动画结束
            this.start = false
        },
        getHistoryHandle() {
            // 获取搜索历史
            getHistory().then(res => {
                if (res) {
                    this.historyList = res.data
                }
            })
        },
        getHotSearchHandle() {
            // 获取热门搜索
            getHotSearch().then(res => {
                if (res) {
                    this.hotList = res.data
                }
            })
        },
        getWebSearchHandle() {
            // 获取热门搜索
            hotGoods({
                page: this.page,
                is_page: 1,
                per_page: 20
            }).then(res => {
                this.islock = false
                if (res) {
                    this.webList = this.webList.concat(res.data.data)
                }
            })
        },
        searchKeyHandle(key) {
            // 搜索
            this.searckKey = decodeURIComponent(key)
            this.isSearch = true
            this.noMore = false
            this.goodsList = []
            if(!this.$route.query.b && !this.$route.query.skey) {
                this.$router.replace({
                    path: "/zh/search",
                    query: { searchKey: encodeURIComponent(this.searckKey) },
                })   
            }
            this.judgeSearchMethods()
        },
        onClickSearch(e) {
            this.gPage = 1
            this.sortType = 1
            this.goodsList = []
            this.searckKey = e
            this.searchGoodsHandle(e)
        },
        cutSearch() {
            cut_search({keywords: decodeURIComponent(this.searckKey.trim())}).then(res => {
                if(res.code == 20000) {
                    this.moreList = res.data
                }
            })
        },
        searchGoodsHandle(e) {
            this.aloading = true
            // 搜索商品
            let params = {
                search: e ? e : decodeURIComponent(this.searckKey.trim()),
                status: this.sortType,
                page: this.gPage
            }
            if (this.$store.state.isMiniProgram) {
                search_mini(params).then(res => {
                    this.islock = false
                    if (res) {
                        this.goodsList = this.goodsList.concat(res.data.data)
                    } else {
                        this.noMore = true
                    }
                }).catch(() => {
                    this.goodsList = []
                }).finally(() => {
                    this.aloading = false
                })
            } else {
                searchGoods(params).then(res => {
                    this.islock = false
                    if (res) {
                        this.goodsList = this.goodsList.concat(res.data.data)
                    } else {
                        this.noMore = true
                    }
                }).catch(() => {
                    this.goodsList = []
                }).finally(() => {
                    this.aloading = false
                })
            }
            this.cutSearch()
        },
        changeSortType(sort) {
            // 修改排序方法
            if (this.sortType == 4 && sort == 4) {
                sort = 5
            }
            this.sortType = sort
            this.goodsList = []
            this.gPage = 1
            this.noMore = false

            this.judgeSearchMethods()
        },
        judgeSearchMethods() {
            // 判断搜索方法
            if (this.searchType == 'gift') {
                this.searchGiftHandle()
            } else if (this.searchType == 'merchant') {
                this.searchMerchant()
            } else if (this.searchType == 'presale') {
                this.searchPresaleHandle()
            } else {
                this.searchGoodsHandle()
            }
        },
        searchGiftHandle() {
            // 搜索礼物专区商品
            this.aloading = true
            let params = {
                search: this.searckKey,
                status: this.sortType,
                page: this.gPage
            }
            searchGift(params).then(res => {
                this.islock = false
                if (res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                } else {
                    this.noMore = true
                }
            }).catch(() => {
                this.goodsList = []
            }).finally(() => {
                this.aloading = false
            })
            this.cutSearch()
        },
        searchPresaleHandle() {
            // 搜索预售
            this.aloading = true
            let params = {
                search: this.searckKey,
                status: this.sortType,
                page: this.gPage
            }
            searchPresale(params).then(res => {
                this.islock = false
                if (res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                } else {
                    this.noMore = true
                }
            }).catch(() => {
                this.goodsList = []
            }).finally(() => {
                this.aloading = false
            })
            this.cutSearch()
        },
        // 店铺搜索
        searchMerchant() {
            let params = {
                search: this.searckKey,
                status: this.sortType,
                shops_id: this.merchantId,
                page: this.gPage
            }
            searchMerchant('shops', params).then(res => {
                this.islock = false
                if (res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                } else {
                    this.noMore = true
                }
            }).catch(() => {
                this.goodsList = []
            }).finally(() => {
                this.aloading = false
            })
            this.cutSearch()
        },
        delHistoryItemHandle(id, index) {
            // 删除单个历史
            delHistory(id).then(res => {
                if (res) {
                    this.$notify({
                        message: '删除成功',
                        type: 'success'
                    })
                    this.historyList.splice(index, 1)
                    if (this.historyList.length <= 0) {
                        this.isDel = false
                    }
                } else {
                    this.$notify({
                        message: '删除失败，请稍后重试',
                        type: 'warning'
                    })
                }
            })
        },
        // 猜你要搜
        getGuessLike() {

        }
    },
    beforeRouteEnter(to, from, next) {
        // 保存上一个页面路由
        if (to.query.skey && from.matched.length > 0) {
            if (from.matched[0].path != '/zh/goods/detail/:id') {
                localStorage.setItem('back_path', from.fullPath)
            }
        }

        next()
    },
    activated() {
        this.gPage = 1
        this.sortType = 1
        // this.searckKey = ''
        this.searchType = this.$route.query.s
        if (this.$route.query.skey && this.searchType != this.$route.query.skey) {
            // 重新获取数据
            this.searchKeyHandle(this.$route.query.skey)
        }
        if (this.$route.query.searchKey) {
            this.searchKeyHandle(this.$route.query.searchKey)
        }

        if (this.searchType == 'gift') {
            // 礼物专区搜索
            this.gPage = 1
            this.goodsList = []
            this.searckKey = this.$route.query.key
            this.isSearch = true
            this.judgeSearchMethods()
        } else if (this.searchType == 'merchant') {
            // 店铺搜索
            this.gPage = 1
            this.goodsList = []
            this.searckKey = this.$route.query.key
            this.merchantId = this.$route.query.id
            this.isSearch = true
            this.judgeSearchMethods()
        } else if (this.searchType == 'presale') {
            this.gPage = 1
            this.goodsList = []
            this.searckKey = this.$route.query.key
            this.isSearch = true
            this.judgeSearchMethods()
        }
        this.getGuessLike()
    },
    created() {
        // 正常搜索
        // if (this.$route.query.skey) {
        //     this.searchKeyHandle(this.$route.query.skey)
        // }
        // 复制搜索
        // if (this.$route.query.searchKey) {
        //     this.searchKeyHandle(this.$route.query.searchKey)
        // }
        this.getHistoryHandle()
        this.getHotSearchHandle()
        this.getWebSearchHandle()
        // this.getGuessLike()
    }
}